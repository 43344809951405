import './assets/styles/custom-bootstrap.scss';

import { createApp } from 'vue';

import App from './App.vue';

import fmt from '@/modules/format.js';
import auth from '@/modules/auth.js';
import assets from '@/modules/assets.js';
import Tracking from '@/modules/tracking.js';

import router from './router';
import matomo from 'vue-matomo';

const app = createApp(App)
    .use(router)
    .use(matomo, {
    host: "https://viagen.matomo.cloud/",
    siteId: 1,
    trackerFileName: 'matomo',
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: true,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    });

//
// inject a wrapper to matomo tracking, as matomo 
// is loaded async and may not be available on start-up
app.provide("tracking", new Tracking(app));

//
// setup global props
app.config.globalProperties.$fmt = fmt;
app.config.globalProperties.$auth = auth;
app.config.globalProperties.$assets = assets;

app.mount('#app');
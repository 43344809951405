/**
 * Sample taken from https://developer.okta.com/code/vue/okta_vue/
 */

import api from '@/modules/api.js';
import log from '@/modules/logging.js';


const OktaAuth = require('@okta/okta-auth-js').OktaAuth

const authClient = new OktaAuth({
    issuer: process.env.VUE_APP_OKTA_ISSUER,
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback'
});

export default {

    login(email, pass, cb) {

        authClient.authStateManager.subscribe(this.onChange);

        return authClient.signInWithCredentials({
            username: email,
            password: pass
        }).then(transaction => {
            if (transaction.status === 'SUCCESS') {

                log.trace("signInWithCredentials -> transaction: {} ", transaction);

                return authClient.token.getWithoutPrompt({
                    responseType: ['id_token', 'token'],
                    scopes: ['openid', 'email', 'profile'],
                    sessionToken: transaction.sessionToken,
                }).then(response => {

                    //
                    // set token
                    log.trace("getWithoutPrompt -> {}", response);
                    authClient.tokenManager.setTokens(response.tokens);

                    //
                    // get current user and store in local storage
                    // Hint: it looks like 'setTokens' is async (or what ever) and therefore
                    // it is not possible to retrive the access token via the getAccessToken 
                    // function of the authClient (which is strange). So we provide 
                    // the accessToken directly from the response to the getUser() function
                    this.getUser(response.tokens.accessToken.value).then(response => {

                        //
                        // store user in local storage
                        log.trace("Saved user to local storage (Usere: {)}", response);
                        localStorage.setItem("user", JSON.stringify(response));

                        //
                        // callback and events
                        if (cb) {
                            cb(true)
                        }
                        this.onChange(true);
                    }).catch(error => errorHandler(cb, error));
                })
            }
        }).catch(error => {
            errorHandler(cb, error)
        });

        function errorHandler(cb, error) {
            log.error("Failed to login user: {}", error);
            if (cb) {
                cb(false);
            }
            localStorage.removeItem("user");
            this.onChange(false);
        }
    },

    getAccessToken() {
        return authClient.getAccessToken();
    },

    signOut() {
        return authClient.signOut().then(() => {
            localStorage.removeItem("user");
        });
    },

    isAuthenticated() {
        return authClient.isAuthenticated();
    },

    getUser(accessToken) {
        // return authClient.getUser();
        return api.getCurrentUser(accessToken);
    },

    hasRole(role) {

        let userJson = localStorage.getItem('user');
        if (userJson) {
            let user = JSON.parse(userJson);
            // log.trace("hasRole() User of local storage {} {}", role, user);
            return user.roles.find(e => e.trim().toLowerCase() === role.trim().toLowerCase()) != undefined;
        }
        return false;
    },

    /**
     * Handler to emit events etc
     */
    onChange(e) {
        log.trace("Auth state changed to {}", e);
    },
}
